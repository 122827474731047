import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikProvider, useFormik } from 'formik';
import { queryCache, useMutation } from 'react-query';
import { Poppins, Spacer } from 'src/common';
import { Guard } from '../Guard';
import { Modal } from '../comps';
import TabsMenu from '../../TabsMenu';
import { FromCsv } from './forms';
import { motion } from 'framer-motion';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import IncidentForm from 'src/components/formComposed/IncidentForm';
import { incidentInitialValues, incidentValidationSchema } from 'src/components/formComposed/IncidentForm/util';
import { createIncident } from 'src/api/incidents';
import { QKeys } from 'src/api/types';

const StyledModal = styled(Modal)`
  width: 1568px;
  min-height: 200px;
  padding: 0;
  overflow: hidden;

  .content {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
  }
`;

interface AddIncidentModalProps {
  onClose: () => void;
}

export const AddIncidentModal: React.FC<AddIncidentModalProps> = ({ onClose }) => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [activeTab, setActiveTab] = useState<'from_new' | 'from_csv'>('from_new');
  const [responseErr, setResponseErr] = useState('');
  const { usersOptios } = useAuth();

  // csv
  const [isScvDirty, setIsScvDirty] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [readyForUpload, setReadyForUpload] = useState(false);

  const onSuccess = () => {
    queryCache.invalidateQueries(QKeys.Incidents);
    onClose();
  };

  const onError = (err: Error) => setResponseErr(err.message || 'Server error');

  const [addFromNew] = useMutation(createIncident, { onSuccess, onError });

  const formikNew = useFormik({
    initialValues: incidentInitialValues,
    validationSchema: incidentValidationSchema,
    validateOnMount: true,

    onSubmit: async (values) => {
      setResponseErr('');

      const { userAdd } = selectUserOption({
        usersOptios,
        selectedUser: values.owner,
      });

      return addFromNew({ ...values, owner: userAdd });
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={() => onClose()}
      isOpen={isCloseRequested}
      isDirty={formikNew.dirty || isScvDirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen center={false}>
          <div className="content">
            <Spacer $px={40} />
            <div className="h-padding">
              <Poppins className="m-title" px={28}>
                Add Incident
              </Poppins>
              <Spacer $px={40} />
              <TabsMenu
                tabs={[
                  { label: 'Enter Incident Details', value: 'from_new', dataCy: 'from_new' },
                  // { label: 'Import From CSV', value: 'from_csv', dataCy: 'from_csv' },
                ]}
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                  mpEvent(MPEvents.ButtonClick, {
                    button: tab === 'from_new' ? 'Tab:Enter Incident Details' : 'Tab:Import From CSV',
                    modal: 'Add Incident modal',
                    tags: ['INCIDENT'],
                  });
                }}
              />
              <Spacer $px={45} />
            </div>
            {activeTab === 'from_new' && (
              <FormikProvider value={formikNew}>
                <div className="h-padding">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0, duration: 0.1 }}
                  >
                    <IncidentForm
                      buttonTitle={'CREATE'}
                      onSubmitCb={() =>
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Create',
                          modal: 'Incident modal',
                          tags: ['INCIDENT'],
                        })
                      }
                    />
                  </motion.div>
                </div>
              </FormikProvider>
            )}
            {/* {activeTab === 'from_csv' && (
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0, duration: 0.1 }}>
                <FromCsv
                  onClose={onClose}
                  setIsScvDirty={setIsScvDirty}
                  state={{ file, setFile, readyForUpload, setReadyForUpload }}
                />
              </motion.div>
            )} */}

            {!!responseErr && (
              <div className="h-padding">
                <Spacer $px={15} />
                <div className="error">{responseErr}</div>
              </div>
            )}
            <Spacer $px={40} />
          </div>
        </StyledModal>
      }
    />
  );
};
