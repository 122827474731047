import React from 'react';
import { WizzardWorkspaceModal } from './WizzardWorkspaceModal';
import { useAuth } from 'src/state/auth';
import { useStateSelector } from 'src/redux';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { queryCache } from 'react-query';
import { QKeys } from 'src/api/types';
import useWzardInit from './useWzardInit';

export const WizzardWorkspace = () => {
  const { userFeatures } = useAuth();
  const wizardWorkspace = useStateSelector(({ app }) => app.wizardWorkspace);
  const { setWizardWorkspace } = useActionsApp();

  const { wizardValues, isLoading, isStdVarComplete } = useWzardInit();

  if (!userFeatures.app.wizard) return null;

  if (wizardWorkspace)
    return (
      <WizzardWorkspaceModal
        wizardData={wizardValues}
        isLoading={isLoading}
        isStdVarComplete={isStdVarComplete}
        onClose={() => {
          setWizardWorkspace(null);
          queryCache.invalidateQueries([QKeys.Wizard]);
        }}
      />
    );

  return null;
};
