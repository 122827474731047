import React, { useEffect, useState } from 'react';
import { NoData, Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { Header } from '../comps';
import { useFormikContext } from 'formik';
import { ValueRow, ValueRowSimple } from './comps';
import { getControlName, getFrameworkName, renderVariableValue } from './util';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import { getFrameworksLibraries } from 'src/api/frameworklibraries';
import { getControlLibraries } from 'src/api/controllibraries';
import {
  companySectorNaming,
  cyberFrameworkNaming,
  cyberFrameworkOptions,
  SlideId,
  WizardFormValues,
} from 'src/api/types/wizard';

const Div = styled.div`
  .value-grid {
    display: grid;
    grid-gap: 10px;
    &--extra-space {
      grid-gap: 20px;
    }
    &__list {
      display: grid;
      grid-gap: 10px;
      align-items: center;
      span {
        text-align: right;
      }
    }
  }
`;

interface OrgDetailsProps {
  onChangeSlide: (id: SlideId) => void;
}

export const OrgDetails: React.FC<OrgDetailsProps> = ({ onChangeSlide }) => {
  const [frameworkId, setFrameworkId] = useState<string | undefined>(undefined);
  const { values } = useFormikContext<WizardFormValues>();
  const { data: frameworks = [] } = useQuery(QKeys.Frameworks, getFrameworksLibraries);

  const { data: controlLibs = [] } = useQuery(
    [QKeys.ControlLibraries, frameworkId],
    () => getControlLibraries(frameworkId as string),
    { enabled: !!frameworkId },
  );

  const developsSoftware = values.softwareDev.developsSoftware;
  const cyberInsurance = values.cyberInsurance.cyberInsurance;

  const organisationValues = [
    {
      title: "What is your company's sector?",
      value: values.organisation.sector ? (
        <Poppins px={14}>{companySectorNaming[values.organisation.sector]}</Poppins>
      ) : (
        <NoData iconOnly />
      ),
    },
    {
      title: "What is your company's annual revenue?",
      variableName: 'Annual revenue',
      value: renderVariableValue(values.organisation.annualRevenue, true, true),
    },
    {
      title: 'How many staff does your organisation have?',
      variableName: 'Number of staff',
      value: renderVariableValue(values.organisation.numberOfStaff, false, true),
    },
    {
      title: 'What is your annual wage bill?',
      variableName: 'Annual wage bill',
      value: renderVariableValue(values.organisation.annualWageBill, true, true),
    },
    {
      title: 'What is the approximate number of working days per year?',
      variableName: 'Average number of working days per year',
      value: renderVariableValue(values.organisation.avgWorkingDays, false, true),
    },
    {
      title: 'What is your annual cyber spend?',
      variableName: 'Annual cyber spend',
      value: renderVariableValue(values.organisation.annualCyberSpend, true, true),
    },
    {
      title: "How much would it cost to replace a typical staff member's computer and/or phone?",
      variableName: 'Average staff hardware cost',
      value: renderVariableValue(values.organisation.avgStaffHardwareCost, true, true),
    },
    {
      title: 'Do you use a managed service provider?',
      value: <Poppins px={14}>{values.organisation.usesManagedServiceProvider ? 'Yes' : 'No'}</Poppins>,
    },
  ];

  const customersValues = [
    {
      title: 'How many customers does your organisation have?',
      variableName: 'Number of customers',
      value: renderVariableValue(values.customers.numberOfCustomers, false, true),
    },
    {
      title: 'What is the average annual revenue from a typical customer?',
      variableName: 'Average contract value',
      value: renderVariableValue(values.customers.avgContractValue, true, true),
    },
    {
      title: 'How many personal data records do you hold?',
      variableName: 'Number of data records',
      value: renderVariableValue(values.customers.numberOfDataRecords, false, true),
    },
  ];

  const softwareDevValues = [
    {
      title: 'Do you develop your own software?',
      value: <Poppins px={14}>{developsSoftware ? 'Yes' : 'No'}</Poppins>,
    },
    {
      title: 'What is the typical cost of a release cycle?',
      variableName: 'Release cycle cost',
      value: renderVariableValue(values.softwareDev.releaseCycleCost, true, developsSoftware),
    },
    {
      title: 'How long is a typical release cycle? (Number of Days)',
      variableName: 'Release cycle length',
      value: renderVariableValue(values.softwareDev.releaseCycleLength, false, developsSoftware),
    },
    {
      title: 'How much is typically invested in R&D annually?',
      variableName: 'Research and development budget',
      value: renderVariableValue(values.softwareDev.researchAndDevelopmentBudget, true, developsSoftware),
    },
  ];

  const supplyChainValues = [
    {
      title: 'What is the average cost to procure/onboard a new supplier?',
      variableName: 'Average cost to procure supplier',
      value: renderVariableValue(values.supplyChain.avgCostToProcureSupplier, true, true),
    },
    {
      title: 'What is the value of the average invoice paid by your organisation?',
      variableName: 'Average invoice value',
      value: renderVariableValue(values.supplyChain.avgInvoiceValue, true, true),
    },
    {
      title: 'What is the value of the largest regular invoice paid by your organisation?',
      variableName: 'Largest regular invoice value',
      value: renderVariableValue(values.supplyChain.largestRegularInvoiceValue, true, true),
    },
  ];

  const cyberInsuranceValues = [
    {
      title: 'Do you have cyber insurance?',
      value: <Poppins px={14}>{cyberInsurance ? 'Yes' : 'No'}</Poppins>,
    },
    {
      title: 'What is the limit of your cyber insurance?',
      variableName: 'Cyber insurance limit',
      value: renderVariableValue(values.cyberInsurance.cyberInsuranceLimit, true, cyberInsurance),
    },
    {
      title: 'What is the excess of your cyber insurance?',
      variableName: 'Cyber insurance excess',
      value: renderVariableValue(values.cyberInsurance.cyberInsuranceExcess, true, cyberInsurance),
    },
    {
      title: 'What is your annual cyber insurance premium?',
      variableName: 'Cyber insurance premium',
      value: renderVariableValue(values.cyberInsurance.cyberInsurancePremium, true, cyberInsurance),
    },
  ];

  useEffect(() => {
    if (values.regulations.primaryControlFramework) {
      const framework = cyberFrameworkOptions.find((el) => el.value === values.regulations.primaryControlFramework);

      if (framework) {
        const uxId = frameworks.find((el) => el.external_id === framework.extId)?.ux_id;
        setFrameworkId(uxId);
      }
    }
  }, [frameworks, values.regulations.primaryControlFramework]);

  const frameworkNames = _.sortBy(
    [
      ...values.regulations.controlFrameworks.map((el) => getFrameworkName(el)),
      ...values.regulations.controlFrameworksOther,
    ],
    (el) => {
      const isInCyberFramework = Object.values(cyberFrameworkNaming).includes(el || '');
      return [!isInCyberFramework, el?.toLowerCase()];
    },
  );

  const primaryControlFramework = getFrameworkName(values.regulations.primaryControlFramework);

  const primaryFrameworkControlsNames = _.sortBy(
    values.regulations.primaryFrameworkControls.map((el) => getControlName(controlLibs, el)),
    (el) => el?.toLowerCase(),
  );

  return (
    <Div>
      <Header title="ORGANISATION DETAILS" gradient />
      <Header title="ORGANISATION" onEdit={() => onChangeSlide(SlideId.organisation)} />
      <div className="value-grid">
        {organisationValues.map((el) => (
          <ValueRow key={el.title} title={el.title} variableName={el.variableName} value={el.value} />
        ))}
      </div>
      <Spacer $px={30} />
      <Header title="CUSTOMERS" onEdit={() => onChangeSlide(SlideId.customers)} />
      <div className="value-grid">
        {customersValues.map((el) => (
          <ValueRow key={el.title} title={el.title} variableName={el.variableName} value={el.value} />
        ))}
      </div>
      <Spacer $px={30} />
      <Header title="SOFTWARE DEVELOPMENT" onEdit={() => onChangeSlide(SlideId.softwareDev)} />
      <div className="value-grid">
        {softwareDevValues.map((el) => (
          <ValueRow key={el.title} title={el.title} variableName={el.variableName} value={el.value} />
        ))}
      </div>
      <Spacer $px={30} />
      <Header title="SUPPLY CHAIN" onEdit={() => onChangeSlide(SlideId.supplyChain)} />
      <div className="value-grid">
        {supplyChainValues.map((el) => (
          <ValueRow key={el.title} title={el.title} variableName={el.variableName} value={el.value} />
        ))}
      </div>
      <Spacer $px={30} />
      <Header title="CYBER INSURANCE" onEdit={() => onChangeSlide(SlideId.cyberInsurance)} />
      <div className="value-grid">
        {cyberInsuranceValues.map((el) => (
          <ValueRow key={el.title} title={el.title} variableName={el.variableName} value={el.value} />
        ))}
      </div>
      <Spacer $px={30} />
      <Header title="REGULATIONS AND FRAMEWORKS" onEdit={() => onChangeSlide(SlideId.regulations)} />
      <div className="value-grid value-grid--extra-space">
        <ValueRowSimple
          title="Which legislation or regulations are they subject to?"
          value={
            <div className="value-grid__list">
              <>
                {values.regulations.legislation.length ? (
                  values.regulations.legislation.map((el) => (
                    <Poppins key={el} px={14}>
                      {el}
                    </Poppins>
                  ))
                ) : (
                  <NoData iconOnly />
                )}
              </>
            </div>
          }
        />
        <ValueRowSimple
          title="Which cyber frameworks do you use?"
          value={
            <div className="value-grid__list">
              <>
                {frameworkNames.length ? (
                  frameworkNames.map((el) => (
                    <Poppins key={el} px={14}>
                      {el}
                    </Poppins>
                  ))
                ) : (
                  <NoData iconOnly />
                )}
              </>
            </div>
          }
        />
        <ValueRowSimple
          title="What is your primary cyber framework?"
          value={
            <div className="value-grid__list">
              {primaryControlFramework ? <Poppins px={14}>{primaryControlFramework}</Poppins> : <NoData iconOnly />}
            </div>
          }
        />
        <ValueRowSimple
          title="(For each framework we have in the platform) Which controls do you have in place?"
          value={
            <div className="value-grid__list">
              {primaryFrameworkControlsNames.length ? (
                primaryFrameworkControlsNames.map((el) => <Poppins px={14}>{el}</Poppins>)
              ) : (
                <NoData iconOnly />
              )}
            </div>
          }
        />
      </div>
      <Spacer $px={30} />
    </Div>
  );
};
