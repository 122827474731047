import {
  Modifiers,
  ProjectedScenario,
  TransferLimitType,
  TreatReduceConsequenceType,
  TreatReduceFrequencyType,
} from 'src/api/types';
import { calcConsequencesLimit, calcModifierReduction } from '../Modifier/Modifiers/util';
import _ from 'lodash';
import { ControlStateItem, ModifierStateItem } from './types';

const ensureObject = <T = {}>(input: any): T => {
  if (_.isString(input)) {
    try {
      return JSON.parse(input);
    } catch (e) {
      console.error('Invalid JSON string:', e);
      // @ts-ignore
      return input;
    }
  }
  return input;
};

type CheckValidity = (a: { mods?: Modifiers; strategy?: string; projectedScenario: ProjectedScenario }) => boolean;

export const checkValidity: CheckValidity = ({ mods, strategy, projectedScenario }) => {
  if (!mods || !strategy) return true;

  let isValid = true;

  if (strategy === 'treat') {
    mods.treat.forEach((el) => {
      if (el.type === 'TREAT_REDUCE_CONSEQUENCE') {
        const lowerValue = calcModifierReduction(projectedScenario.lower, el.data.lower || 0, el.data.isPercentage);
        const upperValue = calcModifierReduction(projectedScenario.upper, el.data.upper || 0, el.data.isPercentage);
        if (lowerValue < 1 || upperValue < 1) isValid = false;
      }

      if (el.type === 'TREAT_REDUCE_FREQUENCY') {
        const frequency_times = calcModifierReduction(
          projectedScenario.frequency_times,
          el.data.value,
          el.data.isPercentage,
        );
        if (frequency_times < 1) isValid = false;
      }
    });
  }

  return isValid;
};

type Recalc = (a: {
  controls: ControlStateItem[];
  original: ProjectedScenario;
  modifiers: ModifierStateItem[] | undefined;
  setControls: (a: ControlStateItem[]) => void;
}) => void;

export const recalc: Recalc = ({ controls, original, modifiers, setControls }) => {
  const calc = controls.map((el, idx) => {
    let projectedScenario = { ...original };

    for (let i = 0; i < idx; i++) {
      const controlItem = controls[i];

      if (controlItem.isEnabled) {
        const strategy = controlItem.control.strategy?.toLowerCase();
        const mods = modifiers?.find((el) => el.id === controlItem.control.id)?.modifiers;

        if (strategy === 'treat') {
          mods?.treat.forEach((mod) => {
            if (mod.type === 'TREAT_REDUCE_CONSEQUENCE') {
              projectedScenario = {
                ...projectedScenario,
                upper: calcModifierReduction(projectedScenario.upper, mod.data.upper, mod.data.isPercentage),
                lower: calcModifierReduction(projectedScenario.lower, mod.data.lower, mod.data.isPercentage),
                frequency_times: projectedScenario.frequency_times,
              };
            }
            if (mod.type === 'TREAT_REDUCE_FREQUENCY') {
              projectedScenario = {
                ...projectedScenario,
                upper: projectedScenario.upper,
                lower: projectedScenario.lower,
                frequency_times: calcModifierReduction(
                  projectedScenario.frequency_times,
                  mod.data.value,
                  mod.data.isPercentage,
                ),
              };
            }
          });
        }
        // if (strategy === 'transfer') {
        //   mods?.transfer.forEach((mod) => {
        //     if (mod.type === 'TRANSFER_LIMIT') {
        //       const calcCons = calcConsequencesLimit({
        //         lowerConsequence: projectedScenario.lower,
        //         upperConsequence: projectedScenario.upper,
        //         excess: mod.data.excess,
        //         tpc: mod.data.tpc,
        //       });

        //       projectedScenario = {
        //         ...projectedScenario,
        //         upper: calcCons.upper,
        //         lower: calcCons.lower,
        //         frequency_times: projectedScenario.frequency_times,
        //       };
        //     }
        //   });
        // }
      }
    }

    return { ...el, projectedScenario };
  });

  if (_.isEqual(controls, calc)) return;
  setControls(calc);
};

export const convertToModifiers = (rawData: any[]): Modifiers => {
  const modifiers: Modifiers = {
    treat: [],
    transfer: [],
  };

  rawData.forEach((item) => {
    switch (item.type) {
      case 'TREAT_REDUCE_CONSEQUENCE':
        const consequence: TreatReduceConsequenceType = {
          type: item.type,
          id: item.ux_id,
          data: {
            lower: item.data.lower,
            upper: item.data.upper,
            isPercentage: item.data.isPercentage,
          },
          isLive: !!item.live,
        };
        modifiers.treat.push(consequence);
        break;

      case 'TREAT_REDUCE_FREQUENCY':
        const frequency: TreatReduceFrequencyType = {
          type: item.type,
          id: item.ux_id,
          data: {
            value: item.data.value,
            isPercentage: item.data.isPercentage,
          },
          isLive: !!item.live,
        };
        modifiers.treat.push(frequency);
        break;

      case 'TRANSFER_LIMIT':
        const transfer: TransferLimitType = {
          type: item.type,
          id: item.ux_id,
          note: item.note,
          data: ensureObject<{
            excess: number;
            tpc: number;
          }>(item.data),
          isLive: !!item.live,
        };
        modifiers.transfer.push(transfer);
        break;
    }
  });

  return modifiers;
};
