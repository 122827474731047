import React, { memo, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/theme/colors';
import CheckBox from 'src/components/form/CheckBox';
import { Poppins, Spacer } from 'src/common';
import Menu from '@mui/material/Menu';
import _ from 'lodash';
import { Error } from '../';
import MaterialInput from '../../MaterialInput';
import Button from '../../Button';
import { Clear } from '@mui/icons-material';

const Div = styled.div<{ $error?: boolean }>`
  .ms-value-container {
    border-radius: 3px;
    border: 1px solid ${colors.stroke};
    height: 48px;
    background: ${colors.cultured};
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    cursor: pointer;
    user-select: none;

    &__value {
      color: ${colors.prussianBlue};
    }
    &__placeholder {
      color: ${colors.cflowerBlue};
      opacity: 0.6;
    }
    &__arrow {
      fill: ${colors.cflowerBlue};
    }
  }

  ${({ $error }) =>
    $error &&
    css`
      .ms-value-container {
        border-color: ${colors.error};
      }
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    .ms-value-container {
      padding: 0 20px;
      height: 40px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .ms-value-container {
      padding: 0 20px;
      font-size: 12px;
      height: 34px;
    }
  }

  .ms-value-container {
    padding-right: 12px !important;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding: 0 !important;
  }
  & .MuiPaper-root {
    border-radius: 3px;
    box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
  }

  .ms-options {
    border: 1px solid red;
    right: 0;

    padding-top: 18px;
    min-width: 250px;
    background-color: white;
    border: 1px solid ${colors.stroke};
    cursor: default;
    display: grid;
    grid-gap: 10px;
    user-select: none;
    box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
    z-index: 100;

    &__title {
      font-size: 14px;
      color: ${colors.cflowerBlue};
      margin: 0 20px;
      margin-bottom: 4px;
    }

    &__item {
      padding: 0 20px;
      display: flex;
      grid-gap: 10px;
      cursor: pointer;
      overflow: hidden;
      align-items: center;

      &--creatable {
        cursor: default;
        justify-content: space-between;

        .msc-delete {
          position: relative;
          z-index: 1;
          color: ${colors.cflowerBlue};
          font-size: 14px;
          cursor: pointer;
          &::before {
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            z-index: -1;
            left: -10px;
          }
          &:hover {
            color: ${colors.red};
          }
        }
      }

      &__title {
        color: ${colors.cflowerBlue};
        font-weight: 500;
        font-size: 14px;
      }
    }

    &__clear {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      border-top: 1px solid ${colors.stroke};
      background-color: ${colors.cultured};
      margin-top: 4px;
      span {
        font-size: 14px;
        color: ${colors.cflowerBlue};
      }

      &:hover {
        cursor: pointer;
        background: ${colors.gradient};
        span {
          color: white;
        }
      }
    }
  }

  .ms-creatable {
    display: flex;
    align-items: center;
    justify-content: center;

    input,
    textarea {
      font-size: 12px;
      padding: 10px 22px;
    }
    & input::placeholder,
    textarea::placeholder {
      font-size: 12px;
    }

    button {
      height: 34px;
      min-width: 34px;
      padding: 1px 0.8rem;
    }
  }
`;

interface MultiSelectProps<T = string> {
  options: { label: string; value: T }[];
  value: T[];
  onChange: (value: T[]) => void;
  onBlur?: () => void;
  placeholder?: string;
  dataCy?: string;
  className?: string;
  error?: string | false;
  createable?: {
    values: string[];
    onAdd: (value: string) => void;
    onClear: () => void;
    onRemove?: (value: string) => void;
  };
}

export const MultiSelect = memo(
  <T,>({
    options,
    onChange,
    onBlur,
    value,
    dataCy,
    placeholder = 'Select...',
    className,
    error,
    createable,
  }: MultiSelectProps<T>) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const [creatableText, setCreatableText] = useState('');
    const [creatableErr, setCreatableErr] = useState('');

    useEffect(() => {
      if (createable) {
        // allow no duplicates
        if (createable.values.includes(creatableText)) {
          setCreatableErr('Value already exists');
        } else if (creatableText) {
          setCreatableErr(!creatableText.trim() ? 'Value cannot be empty' : '');
        } else {
          setCreatableErr('');
        }
      }
    }, [createable, creatableText]);

    const handleSelect = (key: T) => {
      let values: T[] = [];
      if (value.includes(key)) {
        values = value.filter((el) => el !== key);
      } else {
        values = [...value, key];
      }
      onChange(values);
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(e.currentTarget);
      e.stopPropagation();
    };

    const isAllSelected = options.every((el) => value.includes(el.value));

    const totalSelected = value.length + (createable?.values.length || 0);

    return (
      <>
        <Div data-cy={dataCy} className={className} onClick={handleClick} $error={!!error}>
          <div className="ms-value-container">
            {totalSelected ? (
              <span className="ms-value-container__value">{totalSelected} selected</span>
            ) : (
              <span className="ms-value-container__placeholder">{placeholder}</span>
            )}
            <svg
              className="ms-value-container__arrow"
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>
        </Div>
        {error && <Error>{error}</Error>}

        <StyledMenu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoFocus={false}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
            onBlur && onBlur();
          }}
        >
          <div className="ms-options" onClick={(e) => e.stopPropagation()}>
            {!!options.length && (
              <div
                className="ms-options__item"
                onClick={() => onChange(isAllSelected ? [] : options.map((el) => el.value))}
              >
                <CheckBox isChecked={isAllSelected} small />
                <Poppins className="ms-options__item__title" nowrap>
                  All
                </Poppins>
              </div>
            )}

            {_.orderBy(options, [(el) => el.label.toLowerCase()]).map((el) => (
              <div
                className="ms-options__item"
                data-cy={`gt-csa-${el.value}`}
                key={el.value as string}
                onClick={() => handleSelect(el.value)}
              >
                <CheckBox isChecked={value.includes(el.value)} small />
                <Poppins className="ms-options__item__title" nowrap>
                  {el.label}
                </Poppins>
              </div>
            ))}

            {!!createable && (
              <>
                <div />
                {createable.values.map((el) => (
                  <div
                    className="ms-options__item ms-options__item--creatable"
                    data-cy={`gt-csa-${el}`}
                    key={`other-${el}`}
                  >
                    <Poppins className="ms-options__item__title" nowrap>
                      {el}
                    </Poppins>
                    {createable.onRemove && (
                      <div
                        className="msc-delete"
                        onClick={() => (createable?.onRemove ? createable?.onRemove(el) : undefined)}
                      >
                        <Clear fontSize="small" />
                      </div>
                    )}
                  </div>
                ))}

                <div className="ms-options__item  ms-creatable">
                  <MaterialInput
                    className={className}
                    value={creatableText}
                    onChange={(e) => setCreatableText(e.target.value)}
                    fullWidth
                    error={!!creatableErr}
                    helperText={creatableErr}
                    onKeyDown={(e) => e.stopPropagation()}
                  />
                  <Button
                    primary
                    disabled={!creatableText}
                    onClick={() => {
                      createable.onAdd(creatableText.trim());
                      setCreatableText('');
                    }}
                  >
                    +
                  </Button>
                </div>
              </>
            )}

            <div
              className="ms-options__clear"
              onClick={() => {
                onChange([]);
                createable?.onClear();
              }}
            >
              <Poppins>Clear all</Poppins>
            </div>
          </div>
        </StyledMenu>
      </>
    );
  },
  (prev, next) => _.isEqual(prev, next),
) as <T>(props: MultiSelectProps<T>) => JSX.Element;
