import React from 'react';
import { DataTitle, InputLabel } from 'src/common';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Modifiers } from 'src/api/types';
import MaterialInput from 'src/components/form/MaterialInput';

const Div = styled.div`
  display: grid;
  grid-gap: 4px;
  margin-left: 40px;
  width: calc(100% - 410px);
`;

const Input = styled(MaterialInput)`
  & input,
  textarea {
    background: white;
  }
`;

interface NoteProps {
  idx: number;
  namePrefix: string;
  disabled?: boolean;
}

export const Note: React.FC<NoteProps> = ({ idx, namePrefix, disabled }) => {
  const { values, setFieldValue, handleBlur } = useFormikContext<Modifiers>();

  return (
    <Div>
      <DataTitle $noMargin nowrap>
        NOTE
      </DataTitle>
      <Input
        id={`${namePrefix}[${idx}].note`}
        value={values[namePrefix as keyof Modifiers][idx]?.note || ''}
        placeholder="Add a note"
        onChange={(e) => setFieldValue(`${namePrefix}[${idx}].note`, e.target.value)}
        onBlur={handleBlur}
        disabled={disabled}
        fullWidth
        multiline
      />
    </Div>
  );
};
