import React from 'react';
import styled from 'styled-components';
import { Block } from '@mui/icons-material';
import Meta from 'src/components/Meta';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useGaEventPageView } from '../../utils/gaEvent';
import colors from 'src/theme/colors';
import { Spacer } from 'src/common';

const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`;
const EmptyTitle = styled.div`
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-top: 67px;
`;

const statusMessage = { 404: 'That item does not exist', 403: 'You do not have permission to access that item' };

interface IErrorPageProps {
  initStatus?: 403 | 404;
}

const ErrorPage: React.FC<IErrorPageProps> = ({ initStatus = 404 }) => {
  const location = useLocation();
  const { status } = qs.parse(location.search);

  useGaEventPageView(`Cydea | ${initStatus || status || 'Error'}`);

  return (
    <>
      <Meta title={`Cydea | ${initStatus || status || 'Error'}`} />
      <Spacer $px={50} />
      <EmptyContainer>
        <Block style={{ color: colors.cflowerBlue, fontSize: '6rem' }} />
        <EmptyTitle data-cy="error-page-status-text">
          {/* @ts-ignore */}
          {statusMessage[initStatus || status] || 'An error occurred'}
        </EmptyTitle>
      </EmptyContainer>
    </>
  );
};

export default ErrorPage;
