import { OrgTreeItem, User } from 'src/api/types';
import _ from 'lodash';

export const genPermissions = (user: User | null) => {
  return {
    app: {
      organisation: {
        createInDomain: user?.permissions?.['app.organisation.create_in_domain'] === '1',
        createAsChild: user?.permissions?.['app.organisation.create_as_child'] === '1',
        createWorkspace: user?.permissions?.['app.organisation.create_workspace'] === '1',
        manageUsers: user?.permissions?.['app.organisation.manage_users'] === '1', // Workspace admin
        editUsers: user?.permissions?.['app.organisation.edit_users'] === '1', // Organisation admin
      },
      export: {
        pdf: user?.permissions?.['app.export.pdf'] === '1',
        csv: user?.permissions?.['app.export.csv'] === '1',
      },
      platform: {
        index: user?.permissions?.['platform.index'] === '1',
        admin: user?.permissions?.['platform.admin'] === '1', // Platform admin
        systems: {
          index: user?.permissions?.['platform.systems.index'] === '1',
          roles: user?.permissions?.['platform.systems.roles'] === '1',
          users: user?.permissions?.['platform.systems.users'] === '1',
          attachment: user?.permissions?.['platform.systems.attachment'] === '1',
        },
      },
    },
  };
};

export const genFeatures = (user: User | null) => {
  return {
    app: {
      audit: {
        controls: {
          report: user?.features?.['app.audit.controls.report'] !== '0',
        },
        report: user?.features?.['app.audit.report'] !== '0',
      },
      workspacevariables: user?.features?.['app.workspacevariables'] !== '0',
      wizard: user?.features?.['app.wizard'] !== '0',
      mixpanel: user?.features?.['app.mixpanel'] !== '0',
    },
  };
};

export const findOrgPath = (data: OrgTreeItem[], targetId?: string): OrgTreeItem[] => {
  if (!targetId) return [];
  const findNode = (node: OrgTreeItem, targetId: string, path: OrgTreeItem[]): boolean => {
    path.push(_.omit(node, 'children'));

    if (node.id === targetId && node.type === 'W') {
      return true;
    }

    if (node.children) {
      for (const child of node.children) {
        if (findNode(child, targetId, path)) {
          return true;
        }
      }
    }

    path.pop();
    return false;
  };

  for (const rootNode of data) {
    const path: OrgTreeItem[] = [];
    if (findNode(rootNode, targetId, path)) {
      return path;
    }
  }

  return [];
};

// export function findOrgPath(data: DataNode[], targetId: string, targetType: string = 'W'): DataNode[] | null {
//   function findNode(node: DataNode, targetId: string, targetType: string, path: DataNode[]): boolean {
//     // Only add the current node to the path if it matches the target type
//     if (node.type === targetType) {
//       path.push(node);
//     }

//     // Check if the current node is the target and has the correct type
//     if (node.id === targetId && node.type === targetType) {
//       return true;
//     }

//     // If the current node has children, search through them
//     if (node.children) {
//       for (const child of node.children) {
//         if (findNode(child, targetId, targetType, path)) {
//           return true;
//         }
//       }
//     }

//     // If the target is not found in this branch, backtrack by removing the last node
//     // Only backtrack if the node had the correct type
//     if (node.type === targetType) {
//       path.pop();
//     }
//     return false;
//   }

//   for (const root of data) {
//     const path: DataNode[] = [];
//     if (findNode(root, targetId, targetType, path)) {
//       return path;
//     }
//   }
//   return null;
// }
