import { queryCache, useMutation } from 'react-query';
import { switchOrganisation } from 'src/api/auth';

type UseWorkspaceSwitch = (a?: { reloleadWindowOnSuccess?: boolean; onError?: (data: any) => void }) => {
  switchOrg: (a: string) => void;
  isLoading: boolean;
  error: any;
};

const useWorkspaceSwitch: UseWorkspaceSwitch = (params = {}) => {
  const { onError, reloleadWindowOnSuccess } = params;

  const [switchOrg, { isLoading, error }] = useMutation(switchOrganisation, {
    onSuccess: () => {
      if (reloleadWindowOnSuccess) {
        window.location.replace('/');
        // queryCache.invalidateQueries();
      } else {
        queryCache.invalidateQueries();
      }
    },
    onError,
  });

  return { switchOrg, isLoading, error };
};

export default useWorkspaceSwitch;
